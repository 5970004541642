import React from 'react'
import { FaAngleLeft } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa6";
function LocalizedArrowIcon({locale}:{locale:string}) {
  return (
    locale == "ar" ? 
        <FaAngleLeft size={18} className="text-Gray" /> :
        <FaAngleRight size={18} className="text-Gray" />
        
  )
}

export default LocalizedArrowIcon