import React from "react";
import { addDays, subDays, format } from "date-fns";
import { useLocale, useTranslations } from "next-intl";
import { ar, enUS } from "date-fns/locale";
import { FaCalendarAlt } from "react-icons/fa";
import { DateRange } from "react-day-picker";
import { toast } from "@/hooks/use-toast";
import SearchCardTitle from "./SearchCardTitle";

function SearchDateFromSection({
  date,
  setDate,
  openDatePopup,
  disabled,
}: {
  date?: DateRange | undefined;
  setDate: (date: DateRange | undefined) => void;
  openDatePopup: () => void;
  disabled?: boolean;
}) {
  const locale = useLocale();

  const getLocale = () => {
    switch (locale) {
      case "ar":
        return {
          ...ar,
          weekdays: [
            "الأحد", // Sunday
            "الاثنين", // Monday
            "الثلاثاء", // Tuesday
            "الأربعاء", // Wednesday
            "الخميس", // Thursday
            "الجمعة", // Friday
            "السبت", // Saturday
          ],
          weekdaysShort: [
            "الأحد", // Sun
            "الاثنين", // Mon
            "الثلاثاء", // Tue
            "الأربعاء", // Wed
            "الخميس", // Thu
            "الجمعة", // Fri
            "السبت", // Sat
          ],
        };
      case "en":
      default:
        return enUS;
    }
  };

  const handleNextDate = () => {
    if (date?.from && date?.to && addDays(date?.from, 1) > date.to) {
      toast({
        title: t("Date can't be after checkout date"),
        variant: "destructive",
      });
      return;
    }
    if (date) {
      setDate({
        from: addDays(date.from || new Date(), 1),
        to: date.to, // Keep 'to' date as it is
      });
    }
  };

  const handlePreviousDate = () => {
    if (date) {
      setDate({
        from: subDays(date.from || new Date(), 1),
        to: date.to, // Keep 'to' date as it is
      });
    }
  };
  const t = useTranslations();
  const today = new Date(); // Get today's date
  const isFromDateDisabled = date?.from && date.from <= today; // Check if date.from is today or in the future
  return (
    <div className="flex flex-col gap-[4px]">
      {/* <p className="font-medium text-[9.75px]">{t('checkInDate')}</p> */}
      <SearchCardTitle title={t("checkInDateV2")} />
      <div
        className="flex items-center gap-[13.11px] py-[4.5px]"
        onClick={() => openDatePopup()}
      >
        {date?.from ? (
          <p className="text-[16px] font-bold">
            {format(date.from, "dd MMMM", { locale: getLocale() })}
          </p>
        ) : (
          <p className="text-[14px] font-bold text-Grey">{t("Choose Date")}</p>
        )}
        <FaCalendarAlt className={!date?.from ? "text-Grey" : ""} />
      </div>
      <div className="flex items-center gap-[20px] text-[9.75px]">
        {!disabled && (
          <button
            className={`font-medium ${isFromDateDisabled ? "text-Grey" : ""}`}
            onClick={handlePreviousDate}
            disabled={isFromDateDisabled}
          >
            {t("back")}
          </button>
        )}
        {!disabled && (
          <button className={`font-medium `} onClick={handleNextDate}>
            {t("next")}
          </button>
        )}
      </div>
    </div>
  );
}

export default SearchDateFromSection;
