"use client";

import { differenceInDays, format } from "date-fns";
import { ar, enUS } from "date-fns/locale"; // Import necessary locales
import * as React from "react";
import { DateRange } from "react-day-picker";

import { Calendar } from "@/components/ui/calendar";
import { cn } from "@/lib/utils";
import ChangeTimeFormat from "@/utils/dateFormat";
import { X } from "lucide-react";
import { useLocale, useTranslations } from "next-intl";
import { FaCalendarAlt } from "react-icons/fa";
import { IoCheckmark } from "react-icons/io5";
import CustomPopup from "./CustomPopup";
import LocalizedArrowIcon from "./LocalizedArrowIcon";
import SearchDateFromSection from "./SearchDateFromSection";
import SearchDateToSection from "./SearchDateToSection";
interface DateRangePickerProps extends React.HTMLAttributes<HTMLDivElement> {
  showTwoDates?: boolean;
  date: DateRange | undefined;
  setDate: (date: DateRange | undefined) => void;
  disabled?: boolean;
}

export function DateRangePicker({
  className,
  showTwoDates,
  date,
  disabled,
  setDate,
}: DateRangePickerProps) {
  const [isDateOpen, setIsDateOpen] = React.useState(false);

  const locale = useLocale();

  const getLocale = () => {
    switch (locale) {
      case "ar":
        return ar;
      case "en":
      default:
        return enUS;
    }
  };

  const nights =
    date?.to && date?.from ? differenceInDays(date?.to, date?.from) : 0;
  const t = useTranslations();
  React.useEffect(() => {
    setDate({ from: undefined, to: undefined });
  }, []);
  const nightNames = (nights: number) => {
    if (nights == 1) {
      return t("night");
    } else if (nights == 2) {
      return locale == "ar" ? "ليلتان" : `${nights} ${t("Nights")}`;
    } else if (nights > 2 && nights < 11) {
      return locale == "ar" ? `${nights} ليالِ` : `${nights} ${t("Nights")}`;
    } else {
      return locale == "ar" ? `${nights} ليلة` : `${nights} ${t("Nights")}`;
    }
  };

  return (
    <div className={cn("w-full", className)}>
      {showTwoDates ? (
        <div className="2xl:gap-[37px]  gap-[10px] grid grid-cols-2 items-center w-full ">
          <SearchDateFromSection
            setDate={setDate}
            disabled={disabled}
            date={date}
            openDatePopup={() => setIsDateOpen(disabled ? false : true)}
          />
          <SearchDateToSection
            disabled={disabled}
            setDate={setDate}
            date={date}
          />
        </div>
      ) : (
        <div
          onClick={() => setIsDateOpen(true)}
          className="flex flex-col gap-[8px] cursor-pointer"
        >
          {nights > 0 ? (
            <p className=" text-[11px] text-DarkGrey">{nightNames(nights)}</p>
          ) : (
            <p className=" text-[11px] text-DarkGrey">
              {t("selectArriveGoDate")}
            </p>
          )}
          {date?.from || date?.to ? (
            <div className="flex font-bold items-center gap-[5px]">
              <h1>
                {date?.from
                  ? format(date.from, "dd MMMM", {
                      locale: getLocale(),
                    })
                  : null}
              </h1>
              |
              <h1>
                {date?.to
                  ? format(date.to, "dd MMMM", { locale: getLocale() })
                  : null}
              </h1>
              <LocalizedArrowIcon locale={locale} />
            </div>
          ) : (
            <div className=" text-[18px] flex items-center gap-[5px] font-bold">
              <div className=" text-Grey ">{t("Choose Date")}</div>
              <FaCalendarAlt className={"text-Grey"} />
            </div>
          )}
        </div>
      )}

      <CustomPopup
        isOpen={isDateOpen}
        className="bg-gray-400"
        onClose={() => {
          setDate({ from: undefined, to: undefined });
        }}
        setIsOpen={setIsDateOpen}
      >
        <div className="!min-h-[500px]  bg-white rounded-[25px] md:pt-[10px]  pb-[30px] md:px-[60px]">
          <div className="flex justify-end items-end lg:flex-row flex-col w-full">
            <button
              onClick={() => {
                setDate({ from: undefined, to: undefined });
                setIsDateOpen(false);
              }}
              className=" hover:text-Red pt-[30px] rounded-full text-center w-full md:text-end  transition-colors"
            >
              <X size={30} className="w-6 h-6 text-black" />
            </button>
          </div>
          <div className="flex flex-col gap-[25px] mt-3  w-full items-center justify-center">
            <Calendar
              initialFocus
              mode="range"
              defaultMonth={date?.from}
              selected={date}
              selectedDate={date}
              onSelect={(e) => {
                if (date?.from && date?.to && e?.from && e?.to) {
                  setDate({
                    from: undefined,
                    to: undefined,
                  });
                } else setDate(e);
              }}
              numberOfMonths={2}
            />
            <div className="grid w-full grid-cols-3 items-center">
              <div className="text-start  md:ps-[70px] ps-[50px]">
                <div className="font-bold flex flex-col">
                  <span className="text-Red w-fit text-[10px]">
                    {date?.from &&
                      ChangeTimeFormat({
                        time: date?.from,
                        hour: false,
                        minute: false,
                        year: false,
                      })}
                    {date?.from && " - "}
                    {date?.to &&
                      ChangeTimeFormat({
                        time: date?.to,
                        hour: false,
                        minute: false,
                        year: false,
                      })}
                  </span>
                  {nights != 0
                    ? nights == 1
                      ? t("night")
                      : nights == 2
                        ? t("2nights")
                        : nights <= 10
                          ? `${nights} ${t("nights")}`
                          : `${nights} ${t("night")}`
                    : ""}
                </div>
              </div>
              <button
                disabled={!date?.from || !date?.to}
                className={` ${date?.from && date?.to ? " bg-Red" : "!bg-[#d3d3d3] cursor-not-allowed"} rounded-full p-[15px] justify-self-center`}
                onClick={() => {
                  if (date?.from && date?.to) setIsDateOpen(false);
                }}
              >
                <IoCheckmark
                  color="white"
                  className="font-extrabold"
                  size={28}
                />
              </button>
              <div className="text-left hidden md:block"></div>{" "}
              {/* Empty div for space */}
            </div>
          </div>
        </div>
      </CustomPopup>
    </div>
  );
}
