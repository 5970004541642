import React from "react";
import { FaCalendarAlt } from "react-icons/fa";
import { addDays, subDays, format } from "date-fns";
import { useLocale, useTranslations } from "next-intl";
import { ar, enUS } from "date-fns/locale"; // Import necessary locales
import { DateRange } from "react-day-picker";
import { toast } from "@/hooks/use-toast";
import SearchCardTitle from "./SearchCardTitle";

function SearchDateToSection({
  date,
  setDate,
  disabled
}: {
  date: DateRange | undefined;
  setDate: (date: DateRange | undefined) => void;
  disabled?: boolean;
}) {
  const locale = useLocale();
  const t = useTranslations();
  const getLocale = () => {
    switch (locale) {
      case "ar":
        return ar;
      case "en":
      default:
        return enUS;
    }
  };

  const handleNextDate = () => {
    if (date) {
      setDate({
        from: date.from, // Keep 'from' date as it is
        to: date.to ? addDays(date.to, 1) : addDays(new Date(), 1), // Change 'to' date only
      });
    }
  };

  const handlePreviousDate = () => {
    if (date?.from && date?.to && date?.from > subDays(date.to, 1)) {
      toast({
        title: t("Date can't be before checkout date"),
        variant: "destructive",
      });
      return;
    }
    if (date) {
      setDate({
        from: date.from, // Keep 'from' date as it is
        to: date.to ? subDays(date.to, 1) : subDays(new Date(), 1), // Change 'to' date only
      });
    }
  };

  return (
    <div className="flex flex-col gap-[4px]">
      <SearchCardTitle title={t("checkout Date")} />

      <div className="flex items-center gap-[13.11px] py-[4.5px]">
        {date?.to ? (
          <h1 className=" font-bold">
            {format(date.to, "dd MMMM", { locale: getLocale() })}
          </h1>
        ) : (
          <p className="text-[14px] font-bold text-gray-300">
            {t("selectDate")}
          </p>
        )}
        <FaCalendarAlt className={!date?.to ? "text-gray-300" : ""} />
      </div>
      <div className="flex items-center gap-[20px] text-[9.75px]">
  {!disabled&&      <button
          className={`font-medium  ${!date?.to ? "text-gray-300" : ""}`}
          onClick={handlePreviousDate}
        >
          {t("back")}
        </button>}
      {!disabled&&  <button
          className={`font-medium ${!date?.to ? "text-gray-300" : ""}`}
          onClick={handleNextDate}
        >
          {t("next")}
        </button>}
      </div>
    </div>
  );
}

export default SearchDateToSection;
