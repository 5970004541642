"use client";
import { useLocale } from "next-intl";

export default function ChangeTimeFormat({
	time,
	year = true,
	month = true,
	day = true,
	hour = true,
	minute = true,
	hour12 = true,
	localeStringType,
}: {
	time: string | Date;
	year?: boolean;
	month?: boolean;
	day?: boolean;
	hour?: boolean;
	minute?: boolean;
	localeStringType?: string;
	hour12?: boolean;
}) {
	const lang = useLocale(); // Get the user's preferred language
	if (!time) return "";

	const date = new Date(time);

	// Check for invalid date
	if (isNaN(date.getTime())) return "Invalid Date";

	// Add 3 hours to the time (for Saudi Arabia's timezone)
	date.setHours(date.getHours() + 3);

	// Automatically detect the user's locale if not explicitly provided
	const detectedLocale =
		localeStringType || lang || navigator.language || "en-US";

	return date.toLocaleString(detectedLocale, {
		year: year ? "numeric" : undefined,
		month: month ? "short" : undefined,
		day: day ? "numeric" : undefined,
		hour: hour ? "numeric" : undefined,
		minute: minute ? "numeric" : undefined,
		hour12: hour12,
		numberingSystem: "latn", // Use Latin numerals
	});
}
